import React from 'react'
import FavFeed from '../../components/FavFeed'


const FavPage = () => {
    return (
        <FavFeed />
    )
}

export default FavPage